import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const formatMoney = Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 2,
}).format;

const CardStyles = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 3rem 0;
  padding: 3rem;

  border: 1px black solid;
  border-radius: 5px;
  box-shadow: 5px 5px 5px grey;

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 400px auto;
  }
  @media (min-width: 1300px) {
    grid-template-columns: 500px auto;
    font-size: 2.3rem;
  }
`;

const InfoStyles = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 2rem;
  }
`;

const NameStyles = styled.h2`
  margin: 2rem 0;
`;

export default function Card({ service }) {
  return (
    <CardStyles>
      <GatsbyImage
        image={service.image.asset.gatsbyImageData}
        alt={service.name}
        style={{ height: '100%', width: '100%' }}
        orientation={'landscape'}
      />
      <InfoStyles>
        <NameStyles>{service.name}</NameStyles>
        <h4>
          {service.price
            ? `Starting at ${formatMoney(service.price / 100)}`
            : 'Contact us for prices!'}
        </h4>
        <p>{service.description}</p>
      </InfoStyles>
    </CardStyles>
  );
}
