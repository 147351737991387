import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Card from '../components/Card';
import SEO from '../components/SEO';

const PageStyles = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;
  margin: 0 2rem;
  line-height: 3rem;

  @media (min-width: 800px) {
    margin: 5rem 10rem;
  }
  @media (min-width: 1200px) {
    margin: 5rem 20rem;
  }
  @media (min-width: 1500px) {
    margin: 5rem 30rem;
  }
`;

export default function ServicesPage(props) {
  const { nodes } = props.data.services;
  return (
    <>
      <SEO title={'Services'} />
      <PageStyles>
        <h1>Pet Care Services</h1>
        <p>
          Humboldt Critter Sitter provides a variety of professional services
          with various levels of loving pet care, including in-home pet sitting,
          dog socialization activities, day/nightime visits and pet taxi
          services. Our top priority is keeping your pet(s) safe, closely
          followed by happy and healthy!
        </p>
        {nodes.map((node) => {
          return <Card service={node} key={node.name} />;
        })}
      </PageStyles>
    </>
  );
}

export const query = graphql`
  query ServiceQuery {
    services: allSanityService(sort: { fields: price, order: DESC }) {
      nodes {
        name
        price
        description
        image {
          asset {
            gatsbyImageData(width: 400, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
